@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Damion&display=swap');

/* const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
} */


:root {
  --ff-Poppins: 'Poppins', sans-serif;
  --ff-Pacifico: 'Damion', cursive;
  --primary-dark: #0b0b0f;
  --primary: #171721;
  --primary-light: #1e1e2b;
  --secondary: #ea2e32;
  --gray: #56565f;
  --gray-2: #9b9d9f;
  --light-gray: rgba(255, 255, 255, .7);
  --white: #fff;
  --dark-red: #331f2c;
  --green: #51bd45;
  --dark-green: #21331f;
  --blue: #1877f2;
  --dark-blue: #2c2f33;
  /* --primary-light: #58588f; */
  --gold: #eaa92e;
  --dark-gold: #332c2c;
  --purple: #8847ff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Rubik;
}

html {
  scroll-behavior: smooth;
}

body {
  background-repeat: no-repeat;
  background-clip: border-box;
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
  position: relative;
}

::-webkit-scrollbar {
  display: none;
}

.calc {
  min-height: calc(100vh - 80px);
}

/* <h1 id="Under-Develop">under development</h1> */
#Under-Develop {
  color: var(--gray);
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: bolder;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

a {
  color: inherit;
  text-decoration: none;
}